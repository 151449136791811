<template>
  <div class="page-index">
    <!-- 全局loading -->
    <div v-show="visible"
      class="common-loading-wrapper">
      <div class="loading-mask"></div>
      <a-icon type="loading" />
    </div>

    <!--顶部导航-->
    <menu-component></menu-component>

    <!--子页面路由-->
    <div class="main">
      <router-view></router-view>
    </div>

    <footer-component></footer-component>
  </div>

</template>

<script>
/**
 * @file index.vue

 * @desc 首页组件
 * @createTime 2020-10-22
 * @author karsashen <karsashen@tencent.com>
 */
import { mapGetters, mapMutations } from 'vuex';
import menu from '@/components/common/menu';
import footer from '@/components/common/footer';
import i18n from '@/init/get-i18n';

// const test = "测试数据"
const loadingText = i18n.t('BUTTON_TEXT.loading');

export default {
  name: 'index-views',
  components: {
    'menu-component': menu,
    'footer-component': footer,
  },
  data() {
    return {
      text: loadingText,
    };
  },
  computed: {
    ...mapGetters({
      visible: 'getLoadingVisible',
    }),
  },
  mounted() {
    // 初始化弹窗主题颜色
    this.$message.config({
      dark: true,
      duration: 2000,
      offset: 300,
      customClass: 'custom-tips',
    });
  },

  methods: {
    ...mapMutations({
      showLoading: 'updateLoadingVisible',
    }),
  },
};
</script>
<style lang="less" scoped>
.page-index {
  padding-top: 66px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main {
    flex-grow: 1;
    background-color: #fff;
  }
}
</style>
