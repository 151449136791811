/**
 * @file actions.js
 * @desc actions.js
 * @createTime 2020-02-11
 * @author karsashen <karsashen@tencent.com>
 */

import fetchSecList from '@/models/common/fetch-sec-list';
import fetchDetail from '@/models/common/fetch-detail';
import fetchVulnerDetail from '@/models/common/fetch-vulnerdetail';
import fetchVulnerability from '@/models/common/fetch-vulnerability';
import fetchYearList from '@/models/common/fetch-yearlist';
import fetchYearItem from '@/models/common/fetch-yearitem';
import { handleError, addIndex, transformTree } from '@/common/utils';

const actions = {
  async fetchIndex({ commit }, context) {
    context.showLoading(true);

    const result = await fetchSecList.exec().catch((error) => {
      handleError(error, () => {
        context.$message.error({
          content: error ? error.message : 'query fail',
        });
      });

      context.showLoading(false);
    });
    context.showLoading(false);

    if (!result) {
      return;
    }
    // 更新攻击类型
    const secTreeList = transformTree(result.data || []);
    commit('updateSecList', secTreeList || []);
  },

  async fetchDetail({ commit }, context) {
    const { id } = context.$route.params;

    if (_.isEmpty(id)) {
      context.$message.error({
        content: 'not found sec id',
      });
      return;
    }
    context.showLoading(true);

    const result = await Promise.all([
      fetchSecList.exec(),
      fetchDetail.exec({ id }),
    ]).catch((error) => {
      handleError(error, () => {
        context.$message.error({
          content: error ? error.message : 'query detail fail',
        });
      });
      context.showLoading(false);
    });

    context.showLoading(false);

    if (!result || result.length < 2 || !result[0] || !result[1]) {
      context.$message.error({
        content: 'query detail fail',
      });
      return;
    }

    // 更新攻击类型
    commit('updateSecList', transformTree(result[0].data) || []);

    context.detail = result[1].data;

    if (window.beacon) {
      window.beacon.onDirectUserAction('detail_count', {
        detailId: id,
        detailName: context.detail.name,
      });
    }
  },

  async fetchVulnerability({ commit }, context) {
    context.showLoading(true);

    const result = await fetchVulnerability.exec().catch((error) => {
      handleError(error, () => {
        context.$message.error({
          content: error ? error.message : 'query fail',
        });
      });

      context.showLoading(false);
    });
    context.showLoading(false);

    if (!result) {
      return;
    }
    commit('updateVulnerability', result.data || []);
  },

  async fetchVulnerDetail({ commit }, context) {
    const { id } = context.$route.params;
    context.showLoading(true);

    const result = await fetchVulnerDetail.exec({ id }).catch((error) => {
      handleError(error, () => {
        context.$message.error({
          content: error ? error.message : 'query fail',
        });
      });

      context.showLoading(false);
    });
    context.showLoading(false);

    if (!result) {
      return;
    }
    commit('updateVulnerDetail', result.data || []);
  },

  async fetchYearList({ commit }, context) {
    context.showLoading(true);

    const result = await fetchYearList.exec().catch((error) => {
      handleError(error, () => {
        context.$message.error({
          content: error ? error.message : 'query fail',
        });
      });

      context.showLoading(false);
    });
    context.showLoading(false);

    if (!result) {
      return;
    }
    commit('updateYearList', result.data || []);
  },

  async fetchYearItem({ commit }, context) {
    const { year } = context.$route.params;
    context.showLoading(true);

    const result = await fetchYearItem.exec({ year }).catch((error) => {
      handleError(error, () => {
        context.$message.error({
          content: error ? error.message : 'query fail',
        });
      });

      context.showLoading(false);
    });
    context.showLoading(false);

    if (!result) {
      return;
    }
    commit('updateYearItem', addIndex(result.data) || []);
  },

};

export default actions;
