/**
 * @file router.js
 * @desc vue-router 路由主文件
 * @createTime 2019-11-18
 * @author karsashen <karsashen@tencent.com>
 */
import { CONST_ROUTER } from '@/common/router';
import { LANGUAGE } from '@/common/data';

const {
  main,
  detail,
  aboutUs,
  conclusion,
  trackingVulnerability,
} = CONST_ROUTER;

const routes = [
  // 中文页面
  {
    path: '',
    name: main.name,
    component: () => import(/* webpackChunkName: "main" */ './views/main.vue'),
  },
  {
    path: detail.path,
    name: detail.name,
    component: () => import(/* webpackChunkName: "detail" */ './views/detail.vue'),
  },
  {
    path: trackingVulnerability.path,
    name: trackingVulnerability.name,
    component: () => import(/* webpackChunkName: "trackingVulnerability" */ './views/tracking-vulnerability.vue'),
  },
  {
    path: aboutUs.path,
    name: aboutUs.name,
    component: () => import(/* webpackChunkName: "about-us" */ './views/about-us.vue'),
  },
  {
    path: conclusion.path,
    name: conclusion.name,
    component: () => import(/* webpackChunkName: "conclusion" */ './views/conclusion.vue'),
  },
  // 英文页面
  {
    path: `/${LANGUAGE.en}`,
    name: main.name,
    component: () => import(/* webpackChunkName: "main" */ './views/main.vue'),
  },
  {
    path: `/${LANGUAGE.en}${detail.path}`,
    name: detail.name,
    component: () => import(/* webpackChunkName: "detail" */ './views/detail.vue'),
  },
  {
    path: `/${LANGUAGE.en}${trackingVulnerability.path}`,
    name: trackingVulnerability.name,
    component: () => import(/* webpackChunkName: "trackingVulnerability" */ './views/tracking-vulnerability.vue'),
  },
  {
    path: `/${LANGUAGE.en}${aboutUs.path}`,
    name: aboutUs.name,
    component: () => import(/* webpackChunkName: "about-us" */ './views/about-us.vue'),
  },
  {
    path: `/${LANGUAGE.en}${conclusion.path}`,
    name: conclusion.name,
    component: () => import(/* webpackChunkName: "conclusion" */ './views/conclusion.vue'),
  },
];

export default routes;
