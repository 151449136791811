/**
 * @file utils.js
 * @desc 工具类，提供通用工具方法
 * @createTime 2019-11-30
 * @author karsashen <karsashen@tencent.com>
 */

/**
 * @desc 单例模式方法，这里不使用ES6的箭头函数是因为，需要获取此方法的调用方
 *
 * @param {*} Host
 * @returns
 */
import dayjs from 'dayjs';
import { ERROR_DATA } from '@/common/error-code';
import { CONST_ROUTER } from '@/common/router';
import { LANGUAGE } from '@/common/data';

const {
  main, detail,
} = CONST_ROUTER;

function getInstance(Host) {
  if (Host.instance instanceof Host) {
    return Host.instance;
  }

  Host.instance = new Host();
  return Host.instance;
}
function IsPC() {
  const userAgentInfo = navigator.userAgent;
  const Agents = ['Android', 'iPhone',
    'SymbianOS', 'Windows Phone',
    'iPad', 'iPod'];
  let flag = true;

  for (let v = 0; v < Agents.length; v += 1) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }

  return flag;
}

// 序列化成可以存储的json字符串
function serialize(jsonObj) {
  let str = '';
  if (_.isEmpty(jsonObj)) {
    return str;
  }

  try {
    str = JSON.stringify(jsonObj);
  } catch (e) {
    // console.error("serialize fail because:", e)
  }
  return str;
}

// 反序列化成json对象
function unSerialize(jsonStr) {
  let jsonObj = {};
  if (_.isEmpty(jsonStr)) {
    return jsonObj;
  }

  try {
    jsonObj = JSON.parse(jsonStr);
  } catch (e) {
    // console.error("unSerialize fail because:", e)
  }
  return jsonObj;
}

/**
 * @desc 比较两个时间中间间隔多少天
 *
 * @param {*} newDate  新一点的时间
 * @param {*} oldDate  旧的时间
 * @returns
 */
function getDiffDay(newDate, oldDate) {
  const date1 = dayjs(newDate);
  const date2 = dayjs(oldDate);

  return date1.diff(date2, 'day');
}

/**
 * @desc 格式化时间
 *
 * @param {*} time newDate
 * @param {*} formatStr "YYYY/MM/DD HH:mm:ss"
 * @returns
 */
function formatTime(time, formatStr) {
  return dayjs(time).format(formatStr);
}

/**
 * @desc 比较两个时间中间间隔多少时间
 *
 * @param {*} dateBegin
 * @param {*} dateEnd
 * @returns
 */
function diffTime(dateBegin, dateEnd) {
  // 将-转化为/，防止ios系统safari出错
  const begin = new Date(dateBegin.replace(/-/g, '/'));
  const end = new Date(dateEnd.replace(/-/g, '/'));

  const dateDiff = end.getTime() - begin.getTime();// 时间差的毫秒数
  const dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));// 计算出相差天数
  const leave1 = dateDiff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  const hours = Math.floor(leave1 / (3600 * 1000));// 计算出小时数
  // 计算相差分钟数
  const leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  const minutes = Math.floor(leave2 / (60 * 1000));// 计算相差分钟数
  // 计算相差秒数
  const leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  const seconds = Math.round(leave3 / 1000);

  let result = '';
  if (dayDiff > 0) {
    result = `${dayDiff}天`;
  }

  if (hours > 0) {
    result += `${hours}小时`;
  }

  if (minutes >= 0) {
    result += `${minutes}分钟`;
  }

  if (seconds >= 0) {
    result += `${seconds}秒`;
  }
  return result;
}

const getRandom = (val) => val + String(Date.now()) + String(Math.floor(Math.random() * 10e5));

/**
 * @desc 共用的处理error的方法
 *
 * @param {*} error
 */
function handleError(error, callback) {
  if (error && error.code) {
    // abort请求不进行处理
    if (ERROR_DATA.code.ABORT === error.code) {
      return;
    }

    if (_.isEmpty(error.message)) {
      return;
    }

    if (!_.isFunction(callback)) {
      return;
    }

    callback(error.message);
  }
}

/**
 * 通过路由名称获取当前选中的menu的key
 *
 * @param {*} name 路由的component的name
 * @returns
 */
function getRouterName(name) {
  let activated = '';

  switch (name) {
    // 首页
    case main.name:
      activated = main.name;
      break;
    // 详情页
    case detail.name:
      activated = detail.name;
      break;

    default:
      activated = main.name;
      break;
  }

  return activated;
}

function isEn() {
  return window.__lang__ === LANGUAGE.en;
}

/**
 * @desc 格式化tree数据
 * @return tree data
 *
 */
function transformTree(list) {
  const tree = [];
  const record = {};

  for (let i = 0, len = list.length; i < len; i += 1) {
    const item = list[i];
    const { id, parentId: pId } = item;

    if (record[id]) {
      item.children = record[id];
    } else {
      const tmpArr = [];
      record[id] = tmpArr;
      item.children = record[id];
    }

    if (pId !== 0) {
      if (!record[pId]) {
        record[pId] = [];
      }
      record[pId].push(item);
    } else {
      tree.push(item);
    }
  }
  return tree;
}

/**
 * @desc 添加序号
 * @return tree data
 *
 */
function addIndex(list) {
  for (let i = 0; i < list.length; i += 1) {
    list[i].index = i;
  }
  return list;
}

/**
 * @desc 从html字符串中提取图片链接
 * return imageList[]
 *
 */
function getImageListFromHtmlString(contentStr) {
  const imageList = [];

  if (_.isEmpty(contentStr)) {
    return imageList;
  }

  const htmls = new DOMParser().parseFromString(contentStr, 'text/html');
  const imgs = htmls.getElementsByTagName('img');
  if (imgs.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < imgs.length; i++) {
      const ele = imgs[i];
      imageList.push(ele.src);
    }
  }

  return imageList;
}
function getIndexPath() {
  return window.__lang__ === LANGUAGE.en ? `/${LANGUAGE.en}` : '/';
}
export {
  serialize,
  unSerialize,
  getRouterName,
  handleError,
  getRandom,
  getInstance,
  diffTime,
  formatTime,
  getDiffDay,
  isEn,
  getImageListFromHtmlString,
  transformTree,
  getIndexPath,
  addIndex,
  IsPC,
};
