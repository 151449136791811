<template>
  <div class="menu">
    <div class="logo"
      @click="gotoIndex">
      <img class="ai-logo"
        :src="aiLogoPng" />
      <img class="matrix-logo"
        :src="logoPng" />
    </div>
    <div v-if="!isPC"
      class="right">
      <a-icon @click="handleChange"
        class="show-menu-icon"
        :type="showMenu ? 'menu-unfold' : 'menu-fold'" />
      <div v-if="showMenu"
        class="menu-mobile">
        <a-menu :selectedKeys="selected"
          theme="light">
          <template v-for="item in menuList">
            <a-menu-item @click="goto(item.path)"
              :key="item.key">
              {{item.name}}
            </a-menu-item>
          </template>
        </a-menu>
        <div class="menu-button">
          <a-button @click="handleLangClick"
            size="small">{{ $t("language") }}</a-button>
        </div>
      </div>
    </div>

    <div v-else
      class="right">
      <a-menu mode="horizontal"
        :selectedKeys="selected"
        theme="light">
        <template v-for="item in menuList">
          <!--矩阵类型需要遍历子节点
          <a-sub-menu v-if="item.key === 'matrix' && secList.length > 0"
            :key="item.key">
            <span slot="title">
              {{item.name}}
            </span>
            遍历AI攻击类型
          </a-sub-menu> -->
          <a-menu-item @click="goto(item.path)"
            :key="item.key">
            {{item.name}}
          </a-menu-item>
        </template>
      </a-menu>
      <div class="menu-button">
        <a-button @click="handleLangClick"
          size="small">{{ $t("language") }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @file menu.vue
 * @desc 顶部菜单组件
 * @createTime 2020-10-22
 * @author karsashen <karsashen@tencent.com>
 */
import { mapState, mapMutations, mapActions } from 'vuex';
import { LANGUAGE } from '@/common/data';
import logoPng from '@/assets/images/png/logo.png';
import aiLogoPng from '@/assets/images/png/ai-logo.png';
import { CONST_ROUTER } from '@/common/router';
import i18n from '@/init/get-i18n';

const {
  detail, aboutUs, conclusion,
} = CONST_ROUTER;

const MENU_LIST = [
  {
    name: i18n.t('menu.matrix'),
    key: detail.name,
    path: `/${detail.name}`,
  },
  {
    name: i18n.t('menu.trackingVulnerability'),
    key: 'trackingVulnerability',
    path: '/tracking-vulnerability/2021',
  },
  {
    name: i18n.t('menu.aboutUs'),
    key: 'aboutUs',
    path: aboutUs.path,
  },
  {
    name: i18n.t('menu.conclusion'),
    key: 'conclusion',
    path: conclusion.path,
  },

];

export default {
  name: 'menu-component',
  data() {
    return {
      isPC: window.isPC,
      menuList: MENU_LIST,
      showMenu: false,
      logoPng,
      aiLogoPng,
      selected: [''], // 默认选中接口管理
    };
  },

  mounted() {

  },
  watch: {
    $route(to) {
      const { name } = to;

      this.selected = [name || ''];
    },
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
      showLoading: 'updateLoadingVisible',
    }),

    handleChange() {
      this.showMenu = !this.showMenu;
    },

    handleLangClick() {
      let path = window.location.pathname;
      path = path.replace('/en', '');

      if (window.__lang__ === LANGUAGE.en) {
        window.__lang__ = LANGUAGE.zh;
      } else {
        window.__lang__ = LANGUAGE.en;
      }

      this.$router.jump(path);
    },

    gotoIndex() {
      this.$router.jump('/');
    },

    goto(path) {
      this.$router.jump(path);
    },
  },

  computed: {
    ...mapState({
      secList: (state) => state.secList,
    }),
  },
};
</script>

<style lang="less">
.menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: @primary-color;
  height: 65px;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;

  .logo {
    font-size: 26px;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;

    .ai-logo {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .matrix-logo {
      width: 200px;
      height: 65px;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .show-menu-icon {
      color: #fff;
      font-size: 1.75rem;
    }

    .menu-mobile {
      position: fixed;
      right: -1px;
      top: 4rem;
      bottom: 0;
      background-color: @primary-color;

      .ant-menu {
        margin-right: 0;

        .ant-menu-item-selected {
          color: @primary-color !important;
        }
      }

      .menu-button {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        justify-content: center;
      }
    }

    .ant-menu {
      font-weight: bold;
      color: #fff;
      font-size: 1rem;
      background-color: transparent;
      margin-right: 30px;
      border-bottom: none;

      .ant-menu-submenu:hover,
      .ant-menu-submenu-title:hover,
      .ant-menu-item-active,
      .ant-menu-item-selected {
        color: #fff;
        border-color: #fff;
      }

      &.ant-menu-horizontal .ant-menu-item,
      &.ant-menu-horizontal .ant-menu-submenu {
        margin-top: 2px;
      }
    }
  }
}
</style>
