/**
 * @file request.js
 * @desc request 请求基类，封装请求的基本配置，配置默认的拦截器
 * @createTime 2019-11-28
 * @author karsashen <karsashen@tencent.com>
 */
import axios from 'axios';
import { ERROR_DATA, SUCCESS_DATA } from '@/common/error-code';
import { LANGUAGE } from '@/common/data';
import i18n from '@/init/get-i18n';
import Base from './Base';

const failMessage = i18n.t('request.fail');
const { CancelToken } = axios;
const VUE_APP_PROTOCOL = process.env.VUE_APP_PROTOCOL;
const VUE_APP_HOST = process.env.VUE_APP_HOST;
const PRE_PATH = '/api/frontend/';
const HTTP_CONFIG = {
  protocol: VUE_APP_PROTOCOL,
  host: VUE_APP_HOST,
};

const TYPE = {
  get: 'get',
  post: 'post',
};

const TIME_OUT = 60 * 1000; // 默认60秒超时
const {
  code: {
    SERVER_ERROR,
  },

} = ERROR_DATA;

// 上报错误
const reportError = (response) => {
  const { config, data } = response;
  let url = '';
  let type = '';
  let message = '';
  let params = '';

  if (config) {
    url = config.url;
    type = config.method;
    message = JSON.stringify(data);
    params = JSON.stringify(config.data);
  }

  const error = {
    url, type, message, params,
  };

  if (_.isEmpty(url) && _.isEmpty(type)) {
    return;
  }

  if (window.aegis) {
    window.aegis.report(error);
  }
};

// 发起请求之前的拦截器
axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

// 响应返回的拦截器，可以再返回之前对数据做一些format操作
axios.interceptors.response.use(
  (response) => {
    if (_.isEmpty(response.data) || response.data.status !== SUCCESS_DATA.code) {
      // 无效的数据结构
      reportError(response);

      return Promise.reject({
        code: ERROR_DATA.code.INVALID,
        message: response.data ? response.data.msg : failMessage,
      });
    }

    return Promise.resolve(response.data);
  },
  (error) => {
    const defaultMessage = ERROR_DATA.message.DEFAULT;
    let message = defaultMessage;
    const data = error.response ? error.response.data : null;

    if (data) {
      message = data.detail || data.msg || failMessage;
    }

    reportError(error);

    return Promise.reject({
      code: SERVER_ERROR,
      message,
    });
  },
);

/**
 *
 * @desc 默认的Request类，所有的请求都要继承这个基类
 * @export
 * @class Request
 */
export default class Request extends Base {
  constructor() {
    super();
    this.timeout = TIME_OUT; // 默认超时时长
    this.cacheType = ''; // 缓存的类型
    this.type = TYPE.post; // 没有设置的话默认为post请求
    this.needLoading = false; // 请求的时候是否需要loading效果
    this.source = '';
    this.params = {};
    this.format = (data) => data;
  }

  /**
   *
   *
   * @param {Object} params 请求参数
   * @param {string} customUrl 自定义url，非必填项，如果传入，则不使用model的url
   * @param {boolean} useCache 是否使用缓存
   * @returns
   * @memberof Request
   */
  exec(params) {
    let url = '';
    this.lang = window.__lang__;
    const languagePath = this.lang === LANGUAGE.en ? `/${LANGUAGE.en}` : '';

    if (_.isEmpty(HTTP_CONFIG.host)) {
      url = window.location.origin + languagePath + PRE_PATH + this.url;
    } else {
      url = HTTP_CONFIG.protocol + HTTP_CONFIG.host + languagePath + PRE_PATH + this.url;
    }

    this.params = params || {};

    const {
      timeout, format,
    } = this;

    this.source = CancelToken.source();// 取消请求

    return axios[this.type](url, params,
      {
        timeout,
        cancelToken: this.source.token,
      })
      .then((data) => format(data));
  }

  // 取消请求（message 参数是可选的）
  abort(message) {
    const { source } = this;

    if (_.isEmpty(source)) {
      return;
    }

    source.cancel({
      code: ERROR_DATA.code.ABORT, // abort
      message,
    });
  }
}
