/**
 * @file store.js
 * @desc 引入首页通用store
 * @createTime 2019-12-04
 * @author karsashen <karsashen@tencent.com>
 */

import Vue from 'vue';
import Vuex from 'vuex';
import IndexModule from '@/modules/index';

Vue.use(Vuex);

export default new Vuex.Store(IndexModule);
