/**
 * @file router.js
 * @desc 路由配置类
 * @createTime 2019-12-13
 * @author karsashen <karsashen@tencent.com>
 */
const CONST_ROUTER = {
  main: {
    path: '',
    name: 'main',
  },
  detail: {
    path: '/detail/:id?',
    name: 'detail',
  },
  aboutUs: {
    path: '/about-us',
    name: 'aboutUs',
  },
  trackingVulnerability: {
    path: '/tracking-vulnerability/:year?',
    name: 'trackingVulnerability',
  },
  conclusion: {
    path: '/conclusion',
    name: 'conclusion',
  },
};

export {
  CONST_ROUTER,
};
