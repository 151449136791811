/**
 * @file state.js
 * @desc 用例页面需要用到的state
 * @createTime 2019-12-06
 */
const state = {
  loadingVisible: false,
  secList: [],
  vulnerabilityList: [],
  vulnerDetail: [],
  yearList: [],
  yearItem: [],
};

export default state;
