/**
 * @file Base.js
 * @desc Base基类，通用上层继承的一下基本方法，类继承的方法使用static
 * @createTime 2019-11-30
 * @author karsashen <karsashen@tencent.com>
 */
import { getInstance as getIns } from '../common/utils';

export default class Base {
  // 单例方法
  static getInstance() {
    return getIns(this);
  }
}
