/**
 * @file zh.js
 * @desc 中文语言包
 * @createTime 2019-11-30
 * @author karsashen <karsashen@tencent.com>
 */

const zh = {
  language: 'English',
  menu: {
    matrix: '矩阵',
    trackingVulnerability: 'AI/ML算法漏洞',
    contribute: '贡献',
    aboutUs: '关于我们',
    conclusion: '结论',
    home: '首页',
  },

  BUTTON_TEXT: {
    loading: '加载中...',
  },

  matrix: {
    techniques: '项技术',
  },
  DATE: {
    h: '时',
    m: '分',
    s: '秒',
  },

  aiSecList: 'AI攻击类型',
  content: {
    created: '创建时间',
    lastModified: '最后修改于',
  },
  request: {
    fail: '服务端请求异常，请重新操作',
  },
  detail: {
    content: `<p>在过去的几年中，人工智能技术已在许多领域得到广泛应用，包括图像处理，语音识别，自然语言处理等。因此，在安全关键型应用程序中，人工智能技术的安全性问题日益成为人们关注的焦点。研究社区和工业公司。除了性能之外，工程师和用户还应考虑AI系统的安全性问题，并确保AI模型满足不同业务场景的需求，避免恶意控制，影响，欺诈，错误和隐私泄露所造成的严重后果。</p>
    <br />
    <p>为了向开发人员和用户提供有关AI系统安全问题的更好指南，本矩阵旨在发布一个框架，以基于ATT＆CK范式从对手的角度阐明攻击过程和特定的攻击技术，该方法是在网络安全领域已经相对成熟。了解和识别这些技术有助于AI开发人员和维护人员在整个生命周期和相应的解决方案中认识到AI系统的潜在风险，从而为AI系统的应用和部署提供必不可少的技术保证。</p>`,
  },
  contactUs: {
    title: '联系我们',
  },
  aboutUs: {
    aiLab: {
      title: '腾讯AI实验室',
      desc:
        '腾讯AI实验室是腾讯的企业AI实验室，于2016年4月在深圳成立。借助腾讯长期积累的丰富应用场景，大数据，计算能力和一流人才，AI Lab的基础在未来，开放和合作，并致力于不断提高AI的理解，决策和创造力，朝着“使AI无处不在”的愿景迈进。',
    },
    zhuqueLab: {
      title: '腾讯安全平台部/朱雀实验室',
      desc:
        '腾讯安全平台部/朱雀实验室专注于作战水平综合攻击和AI安全技术的研究，以促进攻击和防御，预先研究尖端技术和公司的潜在风险业务，并保护腾讯的业务和用户安全。',
    },
    hkUniversity: {
      title: '香港中文大学（深圳）',
      desc:
        '香港中文大学（深圳）成立于2014年3月，是一所按中外合作办学条例设立，传承香港中文大学的办学理念和学术体系的大学。致力于培养具有国际视野、中华传统和社会担当的创新型高层次人才。香港中文大学（深圳）数据科学学院深耕于运筹学、统计学、计算机科学、机器学习、决策科学和运营管理等前沿领域，专注于数据科学方向的人才培养与科学研究。',
    },
    shenzhenBigData: {
      title: '深圳市大数据研究院',
      desc:
        '深圳市大数据研究院致力于建立国际一流的“大数据研究机构和协同研发平台”，以大数据基础理论、面向大数据的未来新型计算体系、大数据驱动的智能应用技术为重点研究方向。深圳市大数据研究院大数据安全计算实验室专注于研究大数据技术所涉及到的各个环节的安全问题，包括数据的完整性、隐私性、以及基于大数据计算所得模型的鲁棒性、可解释性、公平性等问题。',
    },
    authorUint: '作者',
    partners: '合作伙伴',
  },
  conclusion: {
    desc:
      '<p>目前，人工智能技术已广泛应用于人脸支付，人脸安全，语音识别，机器翻译和许多其他场景。人工智能系统的安全性也引起了业界越来越多的关注。对AI模型的恶意攻击可能给用户带来巨大的安全风险。例如攻击者可能使用经过特殊设计的攻击标签来欺骗面部识别系统，从而导致生命和财产损失。</p><br /><p>针对攻击者模型的安全性问题，本矩阵从攻击者的角度总结了攻击者模型整个生命周期各个方面可能存在的安全隐患，并提出了相应的防御建议。该矩阵不仅包含常见的攻击，例如软件攻击，Docker恶意访问攻击和供应链攻击，而且还包括新的攻击方法，例如针对AI模型的反攻击和后门攻击。</p><br />对于AI开发和技术运维人员来说，了解AI系统在整个生命周期中的风险点和防御方法，为AI系统的安全部署和应用着陆提供必要的技术支持是很有帮助的。',
  },
  copyRight: {
    title: '版权和风险声明',
    desc:
      '本矩阵的版权归腾讯，香港中文大学（深圳），深圳市大数据研究院所有。该矩阵仅供一般建议参考。未经专业建议，读者不得根据矩阵中的任何信息采取任何措施。由于使用本矩阵中的信息而导致的任何损失，三方概不负责。',
  },
  indexContent: {
    detail:
      '人工智能技术推动了经济社会的各个领域从数字化，信息化向智能化的发展，但同时也面临着严重的安全性威胁。从数据的恶意污染到算法自身存在技术脆弱性，以及隐私泄露等各方面各种威胁伴随着AI技术的广泛应用随之而来。尽管学术界与工业界针对AI安全问题提出了很多防御方法，但系统化的依据安全原理来整体看待AI安全问题的解决方案尚未被提出。\n\n我们根据安全领域成熟的ATT&CK框架作为安全的理论依托，聚焦当前人工智能风险，涵盖AI生产运行环境下全生命周期过程中的安全问题，提出一套AI安全威胁风险矩阵，确保AI模型在不同的业务场景下的安全性，使其不会被攻击者轻易控制、影响、或欺骗，避免造成结果误判或隐私数据泄漏等严重后果。',
  },
};

export { zh };
