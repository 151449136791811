/**
 * @file fetch-sec-list.js
 * @desc 获取攻击类型列表接口
 * @createTime 2019-12-17
 * @author karsashen <karsashen@tencent.com>
 */
import Request from '@/base/Request';

class FetchYearList extends Request {
  constructor() {
    super();
    this.url = 'yearList';
    this.type = 'post';
    this.format = (data) => data;
  }
}

// 导出一个单例实体
export default FetchYearList.getInstance();
