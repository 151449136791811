/**
 * @file bind-ubt.js
 * @desc 使用灯塔系统绑定用户数据埋点
 * @createTime 2019-11-25
 * @author karsashen <karsashen@tencent.com>
 */
import BeaconAction from '@tencent/beacon-web-sdk';
/**
 * @desc 绑定函数，绑定但window._上
 * @export
 */

const success = () => {
  // console.log(`onReportSuccess : ${e}`)
};
const fail = () => {
  // console.log(`onReportFail : ${e}`)
};

const ENV = process.env.VUE_APP_PRE_PATH;
// 0WEB0KX5Z74YFGUJ  LOGDEBUGKEY00151
if (ENV === 'release') { // release
  const beacon = new BeaconAction({
    appkey: '0WEB0KX5Z74YFGUJ', // 系统或项目id ,必填
    // versionCode: "2.11.1", // 项目版本, 选填
    // channelID: "channel", // 渠道,选填
    // openid: "openid", // 用户id,选填
    isDebug: false, // 是否开启实时联调, 默认关闭
    delay: 1000, // 普通事件延迟上报时间(单位毫秒), 默认3000(3秒),选填
    onReportSuccess: success, // 上报成功回调,选填
    onReportFail: fail, // 上报失败回调,选填
  });

  // 上报PV
  beacon.reportPV();

  beacon.onDirectUserAction('language_type', { code: window.__lang__ });
  // 挂载全局
  window.beacon = beacon;
}
