/**
 * @file jump.js
 * @desc 页面跳转方法，用来进行后续埋点使用
 * @createTime 2019-11-30
 * @author karsashen <karsashen@tencent.com>
 */
import { LANGUAGE } from '@/common/data';
/**
 * @desc jump为刷新页面的跳转
 *
 * @param {String} path
 * @params {Boolean} isRefresh 是否需要刷新
 */
function jump(path, isRefresh) {
  const { protocol, host } = window.location;
  const prePath = window.__lang__ === LANGUAGE.en ? `/${LANGUAGE.en}` : '';

  window.location.href = `${protocol}//${host}${prePath}${path}`;

  // 这里需要用reload强制刷新
  if (isRefresh) {
    window.location.reload();
  }
}

const SLASH = '/';

export default function bindRouter(router) {
  /**
   * @desc goTo为普通的h5路由跳转，不刷新页面
   *       由于应用的path层级太深，而且前面的/api-test这种不会变
   *       所以goTo跳转之前会自动填充这部分路径前缀
   * @param [Object | String]
   * @param [Boolean] needPushRoot 是否需要直接添加到根路径
   */
  router.goTo = (path, needPushRoot) => {
    // 传字符串进来
    if (_.isString(path)) {
      if (needPushRoot) {
        router.push(path);
        return;
      }

      // 没有带斜线为相对路径，直接push处理，例如"api-test/xxx"
      if (path[0] !== SLASH) {
        router.push(path);
        return;
      }

      router.push(path);
      return;
    }

    // 传对象进来没有带斜线为相对路径，直接push处理
    if (path.path[0] !== SLASH) {
      router.push(path);
      return;
    }

    router.push(path);
  };

  router.jump = jump;
}
