/**
 * @file data.js
 * @desc 常用数据的常量类
 * @createTime 2019-11-30
 * @author karsashen <karsashen@tencent.com>
 */
import i18n from '@/init/get-i18n';

// 缓存类型
const AJAX_CACHE_TYPE = {
  session: 'session', // window.sessionStorage
  local: 'local', // window.localStorage
  memory: 'memory',
};
const LANGUAGE = {
  zh: 'zh',
  en: 'en',
};

const SUCCESS_CODE = 0;

const CONST_TIME = {
  defaultCacheTime: '15M', // 默认缓存时间
  dateFormat: {
    day: 'YYYY-MM-DD',
    onlyDay: 'MM-DD',
    fullTime: 'YYYY-MM-DD HH:mm:ss', // 不使用横杠时间2019-10-10，防止safari不兼容
    HHmmss: 'HH:mm:ss', // 时分秒
  },
  timeUnit: {
    D: 'D', // 天
    H: 'H', // 小时
    M: 'M', // 分钟
    S: 'S', // 秒
  },
  unit: {
    hours: { key: 'h', value: i18n.tc('DATE.h') },
    minutes: { key: 'm', value: i18n.tc('DATE.m') },
    seconds: { key: 's', value: i18n.tc('DATE.s') },
  },
};

const WEEK_LIST = [
  { key: '0', value: '周一' },
  { key: '1', value: '周二' },
  { key: '2', value: '周三' },
  { key: '3', value: '周四' },
  { key: '4', value: '周五' },
  { key: '5', value: '周六' },
  { key: '6', value: '周日' },
];

const CONST_KEY = {
  pre_storage_key: 'ZHIYAN_TEST_', // 缓存key的前缀
};

const CONST_TIPS_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
};

const LASTING_CACHE_KEY = {
  'CASE_MANAGER/CASE_DISPLAY_IS_TREE': true,
};

export {
  AJAX_CACHE_TYPE,
  CONST_TIME,
  CONST_KEY,
  CONST_TIPS_TYPE,
  WEEK_LIST,
  SUCCESS_CODE,
  LANGUAGE,
  LASTING_CACHE_KEY,
};
