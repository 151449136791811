/**
 * @file index.js
 * @desc 初始化Vue全局变量文件
 * @createTime 2019-11-25
 * @author karsashen <karsashen@tencent.com>
 */
import bindLodash from './bind-lodash';
import bindRouter from './bind-router';
import './bind-ubt';
import './bind-platform';
// import "./element-ui"
import './ant-design';
import './ten-design';

/**
 *
 * @desc 加载核心的库文件，并且挂载到全局变量上
 * @export
 */
function init(router) {
  // 挂在lodash在window全局对象上
  bindLodash();
  // 绑定跳转函数
  bindRouter(router);
}

export { init };
