/**
 * @file getters.js
 * @desc api-manager的getters
 * @createTime 2019-12-06
 */

const getters = {
  getLoadingVisible: (state) => state.loadingVisible,
  getSecList: (state) => state.secList,
};
export default getters;
