/**
 * @file ten-design.js
 * @desc 从主框架引入ten-design，不需要子应用去手动控制版本
 * @createTime 2019-12-01
 * @author karsashen <karsashen@tencent.com>
 */
import Vue from 'vue';
import { ImageViewer } from '@tencent/ten-design-vue';

Vue.component(ImageViewer.name, ImageViewer);
