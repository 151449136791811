<template>
  <div class="footer">
    <div class="center">
      <div class="logo">
        <img class="ai-lab"
          :src="aiLabImg" />
        <img class="anping"
          :src="zhuqueLogo" />
        <img class="hk-logo"
          :src="hkLogo" />
        <img class="shenzhen-logo"
          :src="shenzhenLogo" />
      </div>
      <div v-if="isPC"
        class="tips">
        Copyright Tencent, CUHKSZ, SRIBD. All rights reserved.
        <a href="http://beian.miit.gov.cn/">粤B2-20090059-1</a>
      </div>
      <div v-else
        class="tips">
        <p>Copyright Tencent, CUHKSZ, SRIBD.</p>
        <p>All rights reserved.</p>
        <p><a href="http://beian.miit.gov.cn/">粤B2-20090059-1</a></p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @file footer.vue
 * @desc footer组件
 * @createTime 2020-10-22
 * @author karsashen <karsashen@tencent.com>
 */
import anpingLogo from '@/assets/images/png/anping-logo.png';
import zhuqueLogo from '@/assets/images/png/zhuque-logo.png';
import aiLabImg from '@/assets/images/ai-lab/ai-lab.png';
import hkLogo from '@/assets/images/ai-lab/hk-logo-bg.png';
import shenzhenLogo from '@/assets/images/ai-lab/shenzhen-big-data.png';

export default {
  name: 'footer-component',

  data() {
    return {
      isPC: window.isPC,
      anpingLogo,
      zhuqueLogo,
      aiLabImg,
      hkLogo,
      shenzhenLogo,
    };
  },
};
</script>
<style lang="less" scoped>
.footer {
  background-color: @primary-color;

  .center {
    margin: 0 auto;

    .logo {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      align-items: center;

      img {
        margin-right: 30px;
      }

      .anping {
        width: 120px;
        height: 120px;
      }

      .hk-logo {
        height: 120px;
      }

      .shenzhen-logo {
        height: 120px;
      }

      .ai-lab {
        height: 66px;
      }
    }

    .tips {
      margin: 15px 0 10px 0;
      height: 50px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      font-size: 16px;

      a {
        color: #fff;
        display: block;
        margin: 8px 0 6px 0;
      }
    }
  }
}
</style>
