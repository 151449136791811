/**
 * @file mutations.js
 * @desc 用例页面mutations
 * @createTime 2019-12-06
 */
const mutations = {
  updateLoadingVisible(state, visible) {
    state.loadingVisible = visible;
  },
  updateSecList(state, list) {
    state.secList = list;
  },
  updateVulnerability(state, list) {
    state.vulnerabilityList = list;
  },
  updateVulnerDetail(state, list) {
    state.vulnerDetail = list;
  },
  updateYearList(state, list) {
    state.yearList = list;
  },
  updateYearItem(state, list) {
    state.yearItem = list;
  },
};

export default mutations;
