/**
 * @file en.js
 * @desc 英文语言包
 * @createTime 2019-11-30
 * @author karsashen <karsashen@tencent.com>
 */
const en = {
  language: '中文',
  menu: {
    matrix: 'Matrices',
    trackingVulnerability: 'Tracking vulnerability in  AI/ML systems',
    contribute: 'Contribute',
    aboutUs: 'About Us',
    conclusion: 'Conclusion',
    home: 'Home',
  },

  BUTTON_TEXT: {
    loading: 'loading...',
  },

  matrix: {
    techniques: 'techniques',
  },

  DATE: {
    h: '时',
    m: '分',
    s: '秒',
  },

  aiSecList: 'AI SEC LIST',
  content: {
    created: 'Created',
    lastModified: 'Last Modified',
  },
  request: {
    fail: 'Server request exception, please operate again',
  },
  detail: {
    content: `<p>In the past few years, AI techniques have gained wide application in a number of fields, including Image Processing, Speech Recognition, Natural Language Processing, etc. Hence, in security critical application senarios, the security issues of AI techniques have increasingly become the focus of research community and industrial corporations. Besides their performance, engineers and users should also take the security problems of the AI systems into account, and ensure the satefy of AI models in different business scenarios, avoiding serious consequences induced by malicious control, influence, fraud, faults and privacy disclosure.</p>
    <br />
    <p>To provide developers and users a better guidance on the security issues of AI systems, this matrix aims to release a framework to illusrate the attack process and specific attack techniques from the adversaries' perspectives, based on ATT&CK paradigm, which is already relatively mature in the network security domain. Understanding and identifying these techniques is helpful for AI developers and maintainers to realize potential risk of AI systems during the overall life cycle and the corresponding solutions, providing essential technical guarantee for the application and deployment of AI systems.</p>`,
  },
  contactUs: {
    title: 'Contact us',
  },
  aboutUs: {
    aiLab: {
      title: 'Tencent AI Lab',
      img: 'ai-lab.png',
      desc:
        'Tencent AI Lab is Tencent\'s enterprise AI Lab, which was established in Shenzhen in April 2016. With the help of Tencent\'s long-term accumulation of rich application scenarios, big data, computing power and first-class talents, AI Lab is based on the future, open and cooperative, and is committed to continuously improving AI\'s understanding, decision-making and creativity, moving towards the vision of \'make AI everywhere\'.',
    },
    zhuqueLab: {
      title: 'Zhuque Laboratory of Tencent Security Platform Department',
      img: 'zhuque-lab.jpg',
      desc:
        'Zhuque Laboratory of Tencent security platform department focuses on the research of combat level comprehensive attack and AI security technology, in order to promote attack and defense, pre-research the potential risks of cutting-edge technology and the company\'s business, and protect Tencent\'s business and user security.',
    },
    hkUniversity: {
      title: 'The Chinese University of Hong Kong, Shenzhen',
      img: 'hk-university.png',
      desc:
        'The Chinese University of Hong Kong, Shenzhen(CUHK-Shenzhen) was established in March 2014. The University is committed to providing top-quality higher education and cultivating innovative talents with a global perspective, Chinese cultural traditions and social responsibilities. The School of Data Science (SDS) of CUHK-Shenzhen is deeply rooted in cutting-edge fields such as operations research, statistics, computer science, machine learning, decision science, and operations management. The SDS focuses on first-class teaching and academic research in Data Science.',
    },
    shenzhenBigData: {
      title: 'Shenzhen Research Institute of Big Data',
      img: 'shenzhen-big-data.jpg',
      desc:
        'The goal of Shenzhen Research Institute of Big Data is to become a world-class big data research institute. The institute focuses on the core issues in big data including big data foundation theory, future new computing system for big data and big data driven intelligent application technology. The Secure Computing Lab of Big Data at Shenzhen Research Institute of Big Data focuses on the research of security issues involved in big data technology. The lab investigates into the data integrity, privacy, model robustness, interpretability and fairness based on the model of big data.',
    },
    authorUint: 'Author\'s Unit',
    partners: 'Partners',
  },
  conclusion: {
    desc:
      '<p>At present, AI technology has been widely used in face payment, face security, speech recognition, machine translation and many other scenarios. The security of AI system has also attracted more and more attention in the industry. Malicious attacks against AI models can bring huge security risks to users. For example, an attacker could use a specially designed attack sticker to trick a facial recognition system, resulting in loss of life and property.</p><br /> <p>Aiming at the security problems of AI model, this matrix, from the perspective of an attacker, summarizes the possible security risks in all aspects of the entire life cycle of AI model, and gives corresponding defense suggestions. This matrix not only includes common attacks such as software attack, Docker malicious access attack and supply chain attack, but also includes new attack methods such as counter attack and backdoor attack against AI model.</p><br /> It is helpful for AI development and technical operation personnel to understand the risk points and defense methods of AI system under the whole life cycle, and provide necessary technical support for the security deployment and application landing of AI system.',
  },
  copyRight: {
    title: 'Copyright and Risk Statement',
    desc:
      'The copyright of this matrix belongs to Tencent, CHUKSZ and SRIBD. The matrix is only for reference of general suggestions. Readers should not take any action according to any information in the matrix without specific professional suggestions. We will not be responsible for any loss caused by using the information in this matrix.',
  },
  indexContent: {
    detail:
      '<p>Artificial intelligence technology has promoted the development of various fields of the economy and society from digitization, informationization to intelligence, but at the same time it is also facing serious security threats. Various threats, from malicious data contamination to the technical vulnerability of the algorithm itself, and privacy leakage, are accompanied by the widespread application of AI technology. Although academia and industry have proposed many defense methods for AI security issues, a systematic solution to AI security issues based on security principles has not yet been proposed.\n\n Based on the mature ATT&CK framework in the security field as the theoretical basis for security, we focus on current artificial intelligence risks, covering security issues in the entire life cycle of AI production and operation environments, and propose a set of AI security threat risk matrix to ensure the security of AI models in different business scenarios, so that they will not be easily controlled, influenced, or deceived by attackers, and avoid serious consequences such as misjudgment of results or leakage of privacy data.</p>',
  },
};
export { en };
