/**
 * @file bind-platform.js
 * @desc 指定平台
 * @createTime 2019-11-25
 * @author karsashen <karsashen@tencent.com>
 */
import { IsPC } from '@/common/utils';

// 挂载全局
window.isPC = IsPC();
if (!window.isPC) {
  import('@/assets/styles/mobile.less');
}
