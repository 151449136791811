/**
 * @file get-i18n.js
 * @desc 按需加载引入element-ui
 * @createTime 2019-12-03
 * @author karsashen <karsashen@tencent.com>
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { zh, en } from '@/common/text';

Vue.use(VueI18n);

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: window.__lang__ || 'zh', // 设置地区，默认为zh
  messages: {
    zh, en,
  }, // 设置地区信息
});

export default i18n;
