/**
 * @file main.js
 * @desc main.js
 * @createTime 2019-11-18
 * @author karsashen <karsashen@tencent.com>
 */
// eval兼容IE浏览器
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import i18n from '@/init/get-i18n';
import { init } from '@/init/index';
import Index from '@/views/index';
import VueRouter from 'vue-router';
import routes from '@/routes';
import store from '@/store';
import '@/assets/styles/main.less';

Vue.config.productionTip = false;
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes,
});

// 初始化全局对象
init(router);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(Index),
}).$mount('#ai-container');
