/**
 * @file ten-design.js
 * @desc 从主框架引入ten-design，不需要子应用去手动控制版本
 * @createTime 2019-12-01
 * @author karsashen <karsashen@tencent.com>
 */
import Vue from 'vue';
import {
  Button, Menu, Icon, message, Breadcrumb, Collapse,
} from 'ant-design-vue';

/* v1.1.3+ 自动注册Button下组件，如Button.Group */
Vue.use(Button);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Breadcrumb);
Vue.use(Collapse);
Vue.prototype.$message = message;
